import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { CSVLink } from "react-csv"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import DownloadIcon from "@material-ui/icons/CloudDownload"

import Logo from "../assets/img/logo.png"
import Loading from "./Loading"

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 130,
    display: "flex",
    flexDirection: "row",
  },
  logo: {
    margin: theme.spacing(2),
    width: 100,
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}))

const UserList = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const { data: { levels = [] } = {}, loading } = useQuery(gql`
    query {
      levels {
        id
        title
        approvedUsers {
          id
          name
          lastname
          email
          institution
          yearsOfResidence
          specialty
          establishment {
            id
            name
          }
        }
      }
    }
  `)
  if (loading)
    return (
      <Loading
        text={
          "Cargando lista de usuarios\nLa sesión es temporal, al finalizar solo cierre la pestaña"
        }
      />
    )
  const users = levels.length > 0 ? levels[selectedTab].approvedUsers : []
  const csvData = [
    [
      "Nombre",
      "Email",
      "Institución",
      "Años de Residencia",
      "Especialidad",
      "Establecimiento LAPP",
    ],
    ...users.map(user => [
      `${user.name} ${user.lastname}`,
      user.email,
      user.institution,
      user.yearsOfResidence > 0 ? user.yearsOfResidence : "",
      user.specialty,
      user.establishment.name,
    ]),
  ]
  return (
    <>
      <AppBar position="fixed">
        <div className={classes.logoContainer}>
          <img src={Logo} alt="LAPP" className={classes.logo} />
          <Typography variant="h4"> · </Typography>
          <Typography variant="h4" align="center">
            Usuarios aprobados
          </Typography>
        </div>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
        >
          <Tab label="Básico"></Tab>
          <Tab label="Avanzado"></Tab>
        </Tabs>
      </AppBar>
      <Container className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Institución</TableCell>
              <TableCell>Años de Residencia</TableCell>
              <TableCell>Especialidad</TableCell>
              <TableCell>Establecimiento LAPP</TableCell>
              <TableCell>
                <CSVLink
                  data={csvData}
                  filename={`${new Date().toISOString()}.usuarios.aprobados.${
                    levels[selectedTab] ? levels[selectedTab].title : "nivel"
                  }.csv`}
                >
                  <DownloadIcon color="primary" />
                </CSVLink>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="user">
                  {user.name} {user.lastname}
                </TableCell>
                <TableCell>{user.email}</TableCell>

                <TableCell>{user.institution}</TableCell>

                <TableCell>
                  {user.yearsOfResidence > 0 ? user.yearsOfResidence : ""}
                </TableCell>

                <TableCell>{user.specialty}</TableCell>
                <TableCell>{user.establishment.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </>
  )
}

export default UserList
